<template>
  <div class="pa-4 h-100-p w-100-p">
    <div class="title mb-4">
      <h1>Keywords</h1>
    </div>

    <div class="pa-4" v-if="folders.length === 0">
      Add files to use keywords
    </div>

    <v-container class="pa-0 pl-4" v-if="folders.length > 0">
      <v-row>
        <v-col cols="3">
          <v-select
              v-model="selectedFolder"
              :items="folders"
              label="Select your fields"
              @change="updateSelectedFolder($event)"
              single-line
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
              v-model="selectedFile"
              :items="files"
              label="Select your files"
              @change="updateSelectedFile($event)"
              single-line
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-btn class="mr-3"
                 color="primary"
                 @click="applyKeywordSearch"
                 :disabled="getApiPending"
          >
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="keyword-container pl-4" v-if="keywords.length > 0">
      <div class="table-container" v-resize="onResize">
        <v-data-table
            :headers="headers"
            :items="keywords"
            :items-per-page="10"
            fixed-header
            :height="windowSize.y - 288"
            item-key="filename"
        >
          <template slot="item.id" slot-scope="props">
            {{ props.index + 1 }}
          </template>
        </v-data-table>
      </div>
    </div>
    <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar()">
    </InfoSnackbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InfoSnackbar from "../components/reuseable/info-snackbar";

export default {
  components: {InfoSnackbar},
  computed: {
    ...mapGetters([
      'getFolders',
      'getKeywordFileSearch',
      'getKeywordFolderSearch',
      'getApiPending',
    ])
  },
  created() {
    if (this.getKeywordFolderSearch) {
      this.updateSelectedFolder(this.getKeywordFolderSearch);
    }

    if (this.getKeywordFileSearch) {
      this.updateSelectedFile(this.getKeywordFileSearch);
    }
  },
  data: function () {
    return {
      selectedFolder: null,
      selectedFile: null,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        {
          text: "Word",
          align: "start",
          value: "word",
          sortable: false,
        },
        {
          text: "TF-IDF",
          align: "start",
          value: "tfidf",
          sortable: false,
        }
      ],
      displayData: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      keywords: [],
      folders: [],
      files: [],
      keywordData: [],
      lastFolder: null,
      showSnackbar: false,
      snackbarText: '',
    };
  },
  methods: {
    closeSnackBar() {
      this.showSnackbar = false;
    },
    updateSelectedFolder(selectedFolder) {
      this.selectedFolder = selectedFolder;

      if (selectedFolder) {
        const filesInSelectedFolder = this.getFolders[selectedFolder].map(file => file.filename);
        let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        filesInSelectedFolder.sort(collator.compare);
        this.files = ["All"].concat(filesInSelectedFolder);
        this.selectedFile = "All";
      } else {
        this.files = [];
        this.selectedFile = null;
      }
    },
    updateSelectedFile(selectedFile) {
      this.selectedFile = selectedFile;
    },
    applyKeywordSearch() {
      if (!this.selectedFolder) {
        return;
      }

      this.$store.dispatch('setKeywordFolderSearch', this.selectedFolder);
      this.$store.dispatch('setKeywordFileSearch', this.selectedFile);

      if (this.lastFolder === this.selectedFolder) {
        this.handleKeywordData();
        return;
      }

      let query = {
        field: false,
        text: false,
        folder: null,
        file: null
      };

      if (this.selectedFile === 'All') {
        query.field = true;
        query.folder = this.selectedFolder;
      } else {
        query.text = true;
        query.file = this.selectedFile;
        query.folder = this.selectedFolder;
      }
      this.lastFolder = this.selectedFolder;

      this.getKeywords(query);
    },
    async getKeywords(query) {
      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.tfidf({query});
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {
          this.keywordData = res.data;
          this.handleKeywordData();
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;
        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }
    },
    handleKeywordData() {
      this.keywords = [];
      let keywordsData = null;
      if (this.selectedFile === 'All') {
        keywordsData = this.keywordData.idf;
      } else {
        keywordsData = this.keywordData.docs.find(file => file.filename === this.selectedFile).tfidf;
      }

      for (const [key, value] of Object.entries(keywordsData)) {
        if (value > 0) {
          this.keywords.push({
            word: key,
            tfidf: value.toFixed(4)
          });
        }
      }

      this.keywords = this.keywords.sort((a, b) => {
        return b.tfidf - a.tfidf;
      });
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
  },
  watch: {
    getFolders: {
      immediate: true,
      handler(folders) {
        this.folders = Object.keys(folders).sort();
      }
    }
  }
};
</script>
